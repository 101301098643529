import 'js-autocomplete/auto-complete.css';
import autocomplete from 'js-autocomplete';

const createAutocompleteFor = (input, filteType) =>
new autocomplete({
  selector: input,
  minChars: 3,
  source: function(term, suggest){
    $.getJSON('/filters',
      { q: term, filter_type: filteType },
      function(data) {
        return data;
    }).then((data) => {
      suggest(data)
    })
  },
});

const createSearchBarAutocomplete = (input) =>
new autocomplete({
  selector: input,
  minChars: 3,
  source: function(term, suggest){
    $.getJSON('/contents?autocomplete=true',
      { q: term },
      function(data) {
        return data;
    }).then((data) => {
      suggest(data)
    })
  },
});

const autocompleteSearch = function() {
  const methodologyInput = document.getElementById('q_by_filter_methodology');
  const diseaseInput = document.getElementById('q_by_filter_disease');
  const therapyInput = document.getElementById('q_by_filter_therapy');
  const hgvsInput = document.getElementById('q_by_filter_hgvs');
  const studyInput = document.getElementById('q_by_filter_study');
  const searchBarInput = document.getElementById('search_bar_input');

  if (methodologyInput) {
    createAutocompleteFor(methodologyInput, "methodology")
  }
  if (diseaseInput) {
    createAutocompleteFor(diseaseInput, "disease")
  }
  if (therapyInput) {
    createAutocompleteFor(therapyInput, "therapy")
  }
  if (hgvsInput) {
    createAutocompleteFor(hgvsInput, "hgvs")
  }
  if (studyInput) {
    createAutocompleteFor(studyInput, "study")
  }
  if (searchBarInput && searchBarInput.classList.contains('autocomplete')) {
    createSearchBarAutocomplete(searchBarInput)
  }
};
const autocompleteCreate = function() {
  if (window.autocompleteCreateArray.length > 0){
    window.autocompleteCreateArray.map(auto => auto.destroy())
    window.autocompleteCreateArray = []
  }
  const filterEntries = $('.create_content_filter_entry')
  $('.create_content_filter_entry').each(function() {
    const filter_type = $(this).find('select').children("option:selected").val()
    const inputNode = $(this).find(".filter_value").get(0)
    const autoCompleteHandler = createAutocompleteFor(inputNode, filter_type)
    window.autocompleteCreateArray.push(autoCompleteHandler) 

  })

};

export { autocompleteSearch, autocompleteCreate };