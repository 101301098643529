// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap';
// import "cocoon";
import { autocompleteSearch, autocompleteCreate } from './autocomplete';

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("slick-carousel")
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

require("./nested-forms/addFields");
require("./nested-forms/removeFields");

import placeholders from "./content_text_placeholder";

import $ from 'jquery';
global.$ = jQuery;

// alert('hello',)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
function submitFiltersFormOnChange(event, page){
  if (event && event.preventDefault) event.preventDefault();
  var elem = document.getElementById('content_search') // or $('#myform')[0] with jQuery
  if (page){
    $("<input />").attr("type", "hidden")
    .attr("name", "page")
    .attr("value", page)
    .appendTo("#content_search");
  }

  $.rails.fire(elem, 'submit');
}
var searchTextTimeout;

function submitFiltersTextOnChange() {
  if (searchTextTimeout) {
    clearTimeout(searchTextTimeout);
  }
  searchTextTimeout = setTimeout(submitFiltersFormOnChange, 1500);
}


window.autocompleteCreateArray = window.autocompleteCreateArray || [];


$(document).on("ready turbolinks:load", function() {
  window.submitFiltersFormOnChange = submitFiltersFormOnChange;
  console.log("turbolinks load")

  if ($('a[href^="#"]')[0]) {
    $('a[href^="#"]').on('click', function(event) {
      var hash = '#' + $(this).attr('href').split('#')[1]
      var element = $(hash)
      if (element.length) {
        event.preventDefault();
        history.pushState(hash, undefined, hash)
        $('html, body').animate({scrollTop: element.offset().top - 56 }, 500)
      }
    });   
  
    window.addEventListener('popstate', function(e) {
      if(e.state && e.state.startsWith('#') && $(e.state).length){
        $('html, body').animate({scrollTop: $(e.state).offset().top - 56}, 500)
      }
    });
  
    $('html, body').on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function(){
      $('html, body').stop();
    });
  }
  if ($("#content_form_links")[0]) {
    $('#content_form_links')
      .on('cocoon:after-insert', function(event, node, old) {
        node.find('select').get(0).addEventListener('change', autocompleteCreate);
        autocompleteCreate();
      })

      $('#content_content_type')
      .on('change', function(event, node, old) {
        console.log($(this).val());
        if ( this.value == 'gene' || this.value == 'disease')
        
        {
          $("#therapy-study").show();
        }
        else
        {
          $("#therapy-study").hide();
        }

        
        let content_type = event.target.value;
        let placeholder = placeholders[content_type]
        if (placeholder && placeholder["de"] && placeholder["en"]) {
          $('#content_text_de').val(placeholder["de"]);
          $('#content_text_en').val(placeholder["en"]);
        } else {
          $('#content_text_de').val("Beschreibe content");
          $('#content_text_en').val("Describe content");
        }
      })
      if (!$('#content_text_de').val()) $('#content_text_de').val(placeholders["gene"]["de"]);
      if (!$('#content_text_en').val()) $('#content_text_en').val(placeholders["gene"]["en"]);

  }
  if ($('.partnersslider__scroller')[0]) {
    $('.partnersslider__scroller').slick({
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    });
  }

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  if ($(".content_search")[0]) {
    autocompleteSearch();

    const searchBarInput = document.getElementById('search_bar_input');
    const searchOnChangelements = $('.search-on-change');
    const geneContentTypeFilter = document.getElementById('q_by_content_type_10');
    const searchFilter = document.getElementById('q_title_en_or_title_de_or_text_en_or_text_de_or_summary_de_or_summary_en_or_api_text_de_or_api_text_en_or_filters_filter_value_cont');
    const geneFilter = document.getElementById('q_by_filter_gene');
    const delSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_del');
    const dupSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_dup');
    const insSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_ins');
    const delinsSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_delins');
    const conSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_con');
    const invSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_inv');
    const extSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_ext');
    const metSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_met');
    const gomSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_gom');
    const lomSuffixFilter = document.getElementById('q_by_filter_hgvs_suffix_lom');
    const methodologyContentTypeFilter = document.getElementById('q_by_content_type_60');
    const methodologyFilter = document.getElementById('q_by_filter_methodology');
    const therapyContentTypeFilter = document.getElementById('q_by_content_type_40');
    const therapyFilter = document.getElementById('q_by_filter_therapy');
    const diseaseContentTypeFilter = document.getElementById('q_by_content_type_50');
    const diseaseFilter = document.getElementById('q_by_filter_disease');
    const studyContentTypeFilter = document.getElementById('q_by_content_type_30');
    const studyFilter = document.getElementById('q_by_filter_study');
    const hgvsFilter = document.getElementById('q_by_filter_hgvs');
    const specialtyFilter = document.getElementById('q_by_filter_specialty');
    
    if (searchBarInput) searchBarInput.addEventListener('keyup', submitFiltersTextOnChange);
    if (searchOnChangelements) searchOnChangelements.on('change', submitFiltersFormOnChange);
    if (searchFilter) searchFilter.addEventListener('keyup', submitFiltersTextOnChange);
    if (geneContentTypeFilter) geneContentTypeFilter.addEventListener('change', submitFiltersFormOnChange);
    if (geneFilter) geneFilter.addEventListener('change', submitFiltersFormOnChange);
    if (delSuffixFilter) delSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (dupSuffixFilter) dupSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (insSuffixFilter) insSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (delinsSuffixFilter) delinsSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (conSuffixFilter) conSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (invSuffixFilter) invSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (extSuffixFilter) extSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (metSuffixFilter) metSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (gomSuffixFilter) gomSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (lomSuffixFilter) lomSuffixFilter.addEventListener('change', submitFiltersFormOnChange);
    if (methodologyContentTypeFilter) methodologyContentTypeFilter.addEventListener('change', submitFiltersFormOnChange);
    if (methodologyFilter) methodologyFilter.addEventListener('change', submitFiltersFormOnChange);
    if (therapyContentTypeFilter) therapyContentTypeFilter.addEventListener('change', submitFiltersFormOnChange);
    if (therapyFilter) therapyFilter.addEventListener('change', submitFiltersFormOnChange);
    if (diseaseFilter) diseaseFilter.addEventListener('change', submitFiltersFormOnChange);
    if (diseaseContentTypeFilter) diseaseContentTypeFilter.addEventListener('change', submitFiltersFormOnChange);
    if (studyFilter) studyFilter.addEventListener('change', submitFiltersFormOnChange);
    if (studyContentTypeFilter) studyContentTypeFilter.addEventListener('change', submitFiltersFormOnChange);
    if (hgvsFilter) hgvsFilter.addEventListener('change', submitFiltersFormOnChange);
    if (specialtyFilter) specialtyFilter.addEventListener('change', submitFiltersFormOnChange);
  }

  // if ($("#cookieConfirm")[0]) {
  //   $('#cookieConfirm')
  //     .on('click', function(event, node, old) {
  //       node.find('select').get(0).addEventListener('change', autocompleteCreate);
  //       autocompleteCreate();
  //     })
  //   }
  const contentshowSearchContainer = document.querySelector(".contents__show__search__container");
  // const contentshowSearch = document.querySelector(".contents__show__search__container #search_bar_input");
  const NavTop = contentshowSearchContainer.offsetTop;

  function fixnavbar(){
    console.log(window.scrollY > (NavTop + 44 ))
    console.log('window.scrollY', window.scrollY, 'NavTop + 44', (NavTop + 44 ))
    if(window.scrollY > (NavTop)){
        contentshowSearchContainer.classList.add("fixed");
    }else if (window.scrollY < (NavTop + 44)){
        contentshowSearchContainer.classList.remove("fixed");
    }
  }
  window.addEventListener("scroll", fixnavbar);

});

    
