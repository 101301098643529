export default {
  "gene": {
    "en": 
`**Gene Symbol**:
**Name**:
**MIM number**:

# Related disorders

# Related targeted therapies

# Further information
Previous symbols:
Previous names:
`,
    "de": 
`# Allgemeines
**Genname**:
**MIM Nummer**:

# Zugehörige Erkrankungen

# Zugehörige gezielte Therapien

# Weitere Informationen
Vorangehende Gennamen:
`
  },
  "contact_person": {
    "en": 
`**Name**:
**Company/Institution**:
**Address**:
`,
    "de":
`**Name**:
**Organisation/Institution**:
**Adresse**:
`
  },
  "study": {
    "en":
`# Administrative Information
**Study name**:
**Trial register ID**: clinicaltrials.gov ID o.ä., ggf. direkter Link
**Study HQ**: 
**Participating regions**: USA/UK/Europe/...

## Study Description

# Recruitment Information
## Recruting...
from:
until:

## Eligibility Criteria
1.
2.
3.

## Exclusion Criteria
- 
- 

# Contacts and Locations
## HQ
...Name and Adress of HQ...

## National centers
### Center 1
...contact details of center 1...

### Center 2
...contact details of center 2...

## International centers
...contact details of all international centers...
`,
    "de":
`# Allgemeine Informationen
**Studienname**:
**Studienregister-Nummer**: clinicaltrials.gov ID o.ä., ggf. direkter Link
**Studienzentrale**: 
**Partizipierende Regionen**: Deutschland/Europa/USA/...

## Studien-Beschreibung

# Rekrutierung
## Zeitraum
Von:
Bis:

## Einschlusskriterien
1.
2.
3.

## Ausschlusskriterien
- 
- 

# Kontakt
## Deutschland
...Name und Adresse des Ansprechpartners inkl. Kontaktdaten...
...ggf. weitere Ansprechpartner in weiteren teilnehmenden Zentren...

## International
...Name und Adresse des Ansprechpartners inkl. Kontaktdaten...
`
  },
  "therapy": {
    "en":
`# Summary
**Substance name**:
**Disorder**:
**Mechanism**:
**Drug name / Producer**:
**Status**: approved in US since XXX / approved in EU since YYY / approval pending in ZZZ

# Detailed Description
## Disorders

## Eligibility criteria / approval limitations

## Mechanism and Expected Benefit

## Potential Risks

## Conventional therapy options

# Literature / Studies
`,
    "de":
`# Zusammenfassung
**Wirkstoffname**:
**Erkrankungen**:
**Wirkmechanismus**:
**Handelsname / Hersteller**:
**Status**: zugelassen in US seit XXX / abgelehnt in EU seit YYY / Zulassung ausstehend in ZZZ / Phase 3 (s. Studie AAA)

# Detaillierte Beschreibung
## Indikation / ggf. Zulassungsbeschränkungen

## Wirkmechanismus und gewünschter Behandlungseffekt

## Relevante Risiken

## Weitere Therapieoptionen

# Literatur / Studien
`
  },
  "disease": {
    "en": 
`# Definition
**Orphanet-Link**:
**MIM-Link**:

# Epidemiology

# Aetiopathogenesis

# Clinical Characteristics

# Genetic Diagnostics

# Therapy options
## Targeted therapies
-
-

## Supporting therapy
-
-


# Further information
`,
    "de":
`# Definition
**Orphanet-Link**:
**MIM-Link**:

# Epidemiologie

# Ätiopathogenese

# Klinik

# (Gen-)Diagnostik

# Therapie-Optionen
## Gezielte molekulare Therapie
-
-

## Symptomatische Therapie
-
-


# Weiterführende Informationen
`
  },
  "methodology": {
    "en":
`# Summary

# Applicable gene defect types

# Therapeutic approach

# Therapeutic effect

# Types of application / vectors

# Examples / approved substances
`,
    "de":
`# Zusammenfassung

# Welche Gendefekt-Typen können behandelt werden?

# Therapieansatz

# Therapieeffekt

# Applikationsmodus / Vektor

# Anwendungsbeispiele / zugelassene Therapien
`
  }
}